import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
                <h1>Messina Messenger-Obituary Section</h1>

                <h2>Hero, 23</h2>

                <p>Hero, 23, of Messina, died yesterday at her father’s home after a short-term battle with heartbreak. Born September 16, 1997, Hero was the beloved only daughter of Messina Mayor Leonato and his late wife, Ursula. May she be remembered not only for her beauty, but also for her laughter, unwavering loyalty, gentleness and innocence. After graduating salutatorian from Messina High School, Hero attended the University of Sicily, where she majored in Hospitality. In addition to her work as an event planner, Hero was an active member of her community, dutifully serving on the Council to Beautify the Piazza. She is survived by her cousin and confidante, Beatrice, and her father. She is proceeded in death by her mother.</p>
            </div>
        </Layout>
    )
}